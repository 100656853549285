<template>
    <div class="login" v-show="isShowLogin" :style="`background-image: url(${loginBg})`">
        <div class="login_content">
            <img class="logo" :src="logoImg" />
            <el-form ref="formData" :model="formData" :rules="rules">
                <el-form-item prop="telNo">
                    <el-input
                        size="medium"
                        v-model.trim="formData.telNo"
                        :maxlength="11"
                        placeholder="请输入账号"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input size="medium" placeholder="请输入密码" show-password v-model="formData.password" />
                </el-form-item>
                <div class="other">
                    <el-link @click="toResetPasswordPage">重置密码</el-link>
                </div>
                <el-form-item>
                    <el-button class="login_btn" size="medium" type="primary" @click="onLogin">登 录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import _config from '@/js/config.js'
import { staffLogin } from '@/js/api/staffApi.js'
import { mapGetters } from 'vuex'

const _localStorageKey = 'loginData'
export default {
    name: 'login',
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            logoImg: _config.logo16_9,
            loginBg: _config.loginBg,

            isShowLogin: false,
            formData: { telNo: '', password: '' },
            rules: {
                telNo: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
        }
    },
    created() {
        document.onkeydown = (event) => {
            if (event.key === 'Enter') {
                this.onLogin()
            }
        }

        this.$store.dispatch('global/storage', { key: _localStorageKey }).then((res) => {
            const _data = res ? JSON.parse(Buffer.from(res, 'base64').toString('utf-8')) : {}
            this.formData.telNo = _data.telNo
            this.formData.password = _data.password
        })

        if (this.userInfo) {
            this.toPage()
        } else {
            this.isShowLogin = true
        }
    },
    destroyed() {
        document.onkeydown = null
    },
    methods: {
        toResetPasswordPage() {
            this.$router.push('/resetPassword')
        },

        onLogin() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    staffLogin({
                        type: 'TEL_PASSWORD',
                        telNo: this.formData.telNo,
                        password: this.formData.password.trim(),
                    }).then((res) => {
                        document.onkeydown = undefined
                        this.$store.dispatch('user/login', { ...res.data }).then(() => {
                            this.toPage()
                        })
                        this.$store.dispatch('global/storage', {
                            key: _localStorageKey,
                            val: Buffer.from(JSON.stringify(this.formData), 'utf-8').toString('base64'),
                        })
                    })
                }
            })
        },
        toPage() {
            const { redirect } = this.$route.query
            if (!redirect) {
                this.$router.push('/content')
            } else if (redirect === 'talkDialog') {
                this.$router.push('/talkDialog?source=login')
            }
        },
    },
}
</script>

<style lang="less" scoped>
.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .login_content {
        width: 480px;
        background: #ffffff;
        border-radius: 12px;
        padding: 0 24px;
        box-sizing: border-box;
        box-shadow: rgba(100, 100, 111, 1) 0px 7px 29px 0px;
        .logo {
            width: 160px;
            display: block;
            margin: 12px auto;
        }
        /deep/ .el-input {
            .el-input__inner {
                height: 42px;
                line-height: 42px;
            }
        }
        .el-form-item {
            margin-bottom: 20px;
        }
        .other {
            .el-link {
                user-select: none;
            }
        }
        .login_btn {
            width: 90%;
            height: 48px;
            font-size: 18px;
            margin: 24px auto 12px;
            display: block;
        }
    }
}

@media screen and (max-width: 800px) {
    .login {
        .login_content {
            width: 90%;
            height: auto;
            border-radius: 6px;
            /deep/ .el-input {
                .el-input__inner {
                    height: 42px;
                    line-height: 42px;
                }
            }
            .logo {
                width: 120px;
            }
            .el-form-item {
                margin-bottom: 20px;
            }
            .login_btn {
                width: 90%;
                height: 42px;
                font-size: 18px;
                margin: 18px auto 12px;
                display: block;
            }
        }
    }
}
</style>
